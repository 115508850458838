@import url("https://fonts.googleapis.com/css2?family=Poppins:wght@300;500;700&display=swap");

/* custom colors  */

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  font-family: "Poppins", sans-serif;
}

:root {
  --bgcolor: #f1f5fd;
  --primary-color: #6f5de7;
  --text-color: #212122;
  --main-text-color: #1b1b1b;
}

html {
  font-size: 62.5%;
}

a {
  text-decoration: none;
  color: rgb(238, 236, 236);
}

h1,
h2,
h3,
h4,
h5,
h6 {
  color: #333333;
}

/* --------------------------------- navbar css style starts 
                        -------------------------------------------------------*/

.navbar {
  width: 90%;
  padding-left: 10%;
}

/*T*/

.navbar-bg {
  background-color: var(--bgcolor);
  padding: 2rem 0;
  z-index: 99;
  position: relative;
}

.navbar-brand {
  font-size: 4.5rem;
}

.navbar li {
  padding: 0 2rem;
}

.navbar li a {
  font-size: 1.75rem;
  /*font-size: 2.2rem;*/
  font-weight: normal;
  /*font-weight:bolder;*/
  position: relative;
}

.navbar li a:hover {
  color: var(--primary-color) !important;
  text-shadow: 0 0.2rem 0.3rem rgba(183, 171, 255, 0.5);
}

.btn-style {
  width: 12rem;
  height: 4rem;
  border-radius: 3rem;
  color: #fff;
  background-color: var(--primary-color);
  text-transform: capitalize;
  font-size: 1.6rem;
}

.btn-style1 {
  width: 18rem;
  height: 4rem;
  border-radius: 3rem;
  color: #fff;
  background-color: var(--primary-color);
  text-transform: capitalize;
  font-size: 1.6rem;
}


.btn-style:first-child {
  margin-right: 2rem;
}

.btn-style:hover {
  border: 0.1rem solid var(--primary-color);
  background-color: transparent;
  color: var(--primary-color);
  box-shadow: 0 0.2rem 0.5rem rgba(183, 171, 255, 0.5);
}

.btn-style1:hover {
  border: 0.1rem solid var(--primary-color);
  background-color: transparent;
  color: var(--primary-color);
  box-shadow: 0 0.2rem 0.5rem rgba(183, 171, 255, 0.5);
}

.btn-style-border {
  border: 0.1rem solid var(--primary-color);
  background-color: transparent;
  color: var(--primary-color);
  box-shadow: 0 0.2rem 0.5rem rgba(183, 171, 255, 0.5);
}

.btn-style-border:hover {
  color: #fff;
  background-color: var(--primary-color);
  text-transform: capitalize;
}

/* --------------------------------- Navbar ends  
                        -------------------------------------------------------*/

/* --------------------------------- Hero sEction  starts  
                        -------------------------------------------------------*/
header {
  background-color: var(--bgcolor);
  min-height: 80vh;
  width: 100%;
}

.my-display-3 {
  font-size: 32px;
  margin-bottom: 1.5rem;
}

.maroon{color :#e31f23;}
.no-padding-top {
  padding-top: 0;
}

.main-hero-container {
  height: 90vh;
  display: flex;
  /* background-color: aqua; */
}

.main-hero-container h3 {
  text-transform: capitalize;
  font-weight: bolder;
}

.main-hero-para {
  font-size: 1.8rem;
  color: var(--text-color);
  margin: 3rem 0 3rem 0;
  /*T*/
  font-weight: lighter;
  width: 75%;
}

.main-hero-container h3 {
  font-weight: lighter;
}

.input-group {
  position: relative !important;
}

.form-control-text {
  position: absolute;
  height: 5rem;
  border: none;
  outline-style: none;
  padding-left: 1.5rem !important;
  font-size: 1.4rem;
  font-weight: lighter;
}

.input-group-button {
  position: absolute;
  right: 79%;
  top: 0.2rem;
  color: #fff;
  background-color: var(--primary-color);
  height: 4rem;
  width: 22%;
  border-radius: 2rem !important;
  text-align: center;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 1.4rem;
}

/* right side of hero section  */

.main-herosection-images {
  position: relative;
}

.main-herosection-images img {

  /* width: 50%; */
  /*w-60,h-75*/
  /*T*/
  /* height: 50%; */
  width: 100%;
  height: 100%;
  /* margin-top: 5rem; */
  /*10rem*/
  box-shadow: 0 0.8rem 0.8rem rgba(0, 0, 0, 0.25);
  border-radius: 1.5rem;
  /*position: absolute;*/
  /*T*/
}

.main-herosection-images .main-hero-img2 {
  position: absolute;
  width: 35%;
  /*w-50,h-30*/
  /*T*/
  height: 20%;
  left: 55%;
  top: 5%;
  /*-2%*/
  box-shadow: 0.8rem 0 0.8rem rgba(0, 0, 0, 0.25);
}

.p-font {
  font-size: 2rem;
}

/* --------------------------------- Hero sEction  ends  
                        -------------------------------------------------------*/

/* --------------------------------- Work Section below hero section   starts  
                        -------------------------------------------------------*/

.work-container {
  width: 80vw;
  padding: 5rem;
  box-shadow: 0 0.4rem 1.2rem rgba(0, 0, 0, 0.1);
  margin-top: -15rem;
  /*-10rem*/
  /*T*/
  background-color: #fff;
  border-radius: 0.5rem;
}

.work-container .main-heading {
  font-size: 3rem;
  font-weight: bolder;
  margin-bottom: 4rem;
  word-spacing: 1rem;
}

.work-container-subdiv {
  padding: 0 1rem;/*T*//*0 6rem*/
}

.my-work-container-subdiv {
  /*T*/
  padding: 0;
  border: 1px solid grey;
}

.my-work-container-subdiv img {
  width: 100%
}

/*T*/

.work-container .sub-heading {
  font-size: 2rem;
  font-weight: normal;
}

.fontawesome-style {
  font-size: 2.3rem;
  color: var(--primary-color);
  width: 6rem;
  height: 6rem;
  border-radius: 50%;
  background-color: var(--bgcolor);
  display: grid;
  place-items: center;
  text-align: center;
  margin: 2rem auto;
}
.trophy2{width:20%;text-align: center;}
.trophy2 img{width:165px;height:auto;}
.my-main-hero-para{margin: 1.5rem 0;font-size: 1.8rem;font-weight: 100;}
/* --------------------------------- Work Section below hero section   ends  
                        -------------------------------------------------------*/

/* --------------------------------- About Us Section STarts  
                        -------------------------------------------------------*/
.common-section {
  margin: 5rem 0;
}

.our-services img {
  width: 40%;
  height: 75%;
  margin-top: 10rem;
  box-shadow: rgba(0, 0, 0, 0.25) 0px 0.8rem 0.8rem;
  border-radius: 1.5rem;
}

.my-our-service-img img {
  margin-left:30%;
}
.our-services .mini-title {
  text-transform: uppercase;
  font-weight: normal;
  margin: 3rem 0;
  text-align: left;
  font-size: 1.5rem;
}

.our-services .main-heading {
  font-weight: bolder;
}

.our-services-info {
  margin-top: 4rem;
}

.our-services .our-services-number {
  width: 3rem;
  height: 3rem;
  border-radius: 50%;
  background-color: transparent;
  border: 0.1rem solid var(--primary-color);
  font-size: 1.5rem;
  color: var(--primary-color);
  display: grid;
  place-items: center;
}

.our-services-data {
  padding-left: 2rem;

}

.our-services-data a {
  padding-left: 2rem;
  color: #0081d1;
}

.our-services .our-services-data p {
  margin: 1rem 0;
}

/* 2nd section of about us  */

.our-services-rightside {
  margin-top: 5rem;/*10rem*//*T*/
}

.our-services-rightside-content {
  padding-left: 8rem;
}

/* --------------------------------- About Us Section ends  
                        -------------------------------------------------------*/
/* --------------------------------- Services Section starts  
                        -------------------------------------------------------*/

.service-main-container {
  margin-top: 5rem;
  padding: 5rem 0;
}

.service-main-container .main-heading {
  margin: 3rem 0;
}

.work-container-subdiv {
  box-shadow: 0 0.1rem 0.4rem rgba(0, 0, 0, 0.16);
}

.work-container-subdiv:hover {
  background-color: var(--bgcolor);
  cursor: pointer;
}

.work-container-subdiv:hover>.fontawesome-style {
  background-color: #fff;
}

.work-container-subdiv .main-hero-para {
  width: 100%;
}

.service-main-container .work-container-subdiv .fontawesome-style {
  text-align: left;
  margin: 2rem 0;
}

@media (min-width: 1400px) {
  .service-container .col-xxl-4 {
    flex: 0 0 auto;
    width: 30% !important;
    margin: auto;
  }
}

@media (min-width: 992px) {
  .service-container .col-lg-4 {
    flex: 0 0 auto;
    width: 30% !important;
    margin: 2.5rem auto;
  }
}

/* --------------------------------- Services Section ends  
                        -------------------------------------------------------*/

/* --------------------------------- Contact US Section starts  
                        -------------------------------------------------------*/
.contactus-section {
  margin: 15rem 0;
}

.contact-leftside img {
  min-width: 80%;
  max-height: 30rem;
  height: auto;
}

.contact-input-feild {
  margin-bottom: 4rem;
}

.contact-rightside form .form-control {
  border: 1px solid #ced4da;
  -webkit-appearance: none;
  height: 5rem;
  border-radius: 0.5rem;
  font-size: 1.6rem;
  padding-left: 1rem;
  font-weight: lighter;
}

.contact-rightside form .form-check-input {
  width: 2rem;
  height: 2rem;
}

.form-checkbox-style {
  margin: 4rem 0;
}

.contact-rightside form .main-hero-para {
  font-size: 1.5rem;
  color: var(--text-color);
  margin: 0;
  font-weight: lighter;
  width: 75%;
  padding: 0 2rem;
}

/* --------------------------------- Contact US Section ends  
                        -------------------------------------------------------*/

/* --------------------------------- Footer  Section starts  
                        -------------------------------------------------------*/

footer {
  padding: 5rem 0;
  background-color: #5c5c5c;
  /*#182334*/
  color: #fff;
}

footer h2 {
  color: #fff;
  font-size: 14px;
  /*T*/
}

.white {
  color: #fff;
}

/*T*/
.white:hover {
  color: #fff;
}

footer li {
  list-style: none;
  font-size: 1.5rem;
  font-weight: lighter;
  color: #fff;
  cursor: pointer;
  margin: 1rem 0;
}

footer li:hover {
  /*color: var(--primary-color);*/
}

footer .fontawesome-style {
  font-size: 2.3rem;
  color: var(--primary-color);
  background-color: transparent;
  width: 0rem;
  height: 0rem;
  margin: 2rem 0;
  cursor: pointer;
}

footer .fontawesome-style:hover {
  transition: all 1s;
  animation: rotatefont 1s linear infinite alternate-reverse;
  color: #fff;
}

@keyframes rotatefont {
  0% {
    transform: scale(0.6);
  }

  100% {
    transform: scale(1.2);
  }
}

footer .main-hero-para {
  margin: 0;
}

/* --------------------------------- Footer  Section ends  
                        -------------------------------------------------------*/

/* --------------------------------- 404 Error Page Section 
                        -------------------------------------------------------*/

#notfound {
  position: relative;
  height: 100vh;
}

#notfound .notfound {
  position: absolute;
  left: 50%;
  top: 50%;
  -webkit-transform: translate(-50%, -50%);
  -ms-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
}

.notfound {
  max-width: 920px;
  width: 100%;
  line-height: 1.4;
  text-align: center;
  padding-left: 15px;
  padding-right: 15px;
}

.notfound .notfound-404 {
  position: absolute;
  height: 100px;
  top: 0;
  left: 50%;
  -webkit-transform: translateX(-50%);
  -ms-transform: translateX(-50%);
  transform: translateX(-50%);
  z-index: -1;
}

.notfound .notfound-404 h1 {
  font-family: "Maven Pro", sans-serif;
  color: #ececec;
  font-weight: 900;
  font-size: 276px;
  margin: 0px;
  position: absolute;
  left: 50%;
  top: 50%;
  -webkit-transform: translate(-50%, -50%);
  -ms-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
}

.notfound h2 {
  font-family: "Maven Pro", sans-serif;
  font-size: 46px;
  color: #000;
  font-weight: 900;
  text-transform: uppercase;
  margin: 0px;
}

.notfound p {
  font-size: 16px;
  color: #000;
  font-weight: 400;
  text-transform: uppercase;
  margin-top: 15px;
}

.notfound a {
  font-size: 14px;
  text-decoration: none;
  text-transform: uppercase;
  background: #189cf0;
  display: inline-block;
  padding: 16px 38px;
  border: 2px solid transparent;
  border-radius: 40px;
  color: #fff;
  font-weight: 400;
  -webkit-transition: 0.2s all;
  transition: 0.2s all;
}

.notfound a:hover {
  background-color: #fff;
  border-color: #189cf0;
  color: #189cf0;
}

/* ---------------------------------Forth Component----------------------------------------------*/


.forthCompbg {
  background-color: #eeecfa;
  min-height: 35vh;/*60vh*//*T*/
  width: 100%;
}

.forth-container {
  width: 80vw;
  padding: 5rem;
  box-shadow: 0 0.4rem 1.2rem rgba(0, 0, 0, 0.1);
  /*margin-top: 10rem;*/
  /*T*/
  background-color: #eeecfa;
  border-radius: 0.5rem;
}

.forth-container .main-heading {
  font-size: 3rem;
  font-weight: bolder;
  margin-bottom: 4rem;
  word-spacing: 1rem;
}

.forth-container-subdiv {
  padding: 0 6rem;
}

.forth-container .sub-heading {
  font-size: 2rem;
  font-weight: normal;
}





@media only screen and (max-width: 480px) {
  .notfound .notfound-404 h1 {
    font-size: 162px;
  }

  .notfound h2 {
    font-size: 26px;
  }
}

/* --------------------------------- 404 Error Page Section  Ends
                        -------------------------------------------------------*/

/* --------------------------------- responsive media queries  
                        -------------------------------------------------------*/

@media (max-width:768px) {   /*T*/
  .trophy2{width:100%}
  .my-display-3 {font-size: 24px;margin-bottom:15px;}
  .col-6 {width: auto }
  .our-services img {margin-top: 0;}
  .my-our-service-img img{margin-top: 8rem;}
  .work-container .main-heading {font-weight: normal;}
  .forth-container .main-heading {font-size: 2rem;font-weight: normal;margin-bottom: 4rem;word-spacing: normal;}
  .our-services-rightside-content {padding-left: 6rem;}
}

@media (min-width:768px) and (max-width:920px){
  header {min-height: 60vh;}
}

@media (min-width: 1500px) {

  .container,
  .container-lg,
  .container-md,
  .container-sm,
  .container-xl,
  .container-xxl {
    /*max-width: 1500px !important;*/
    /*T*/
  }
}

@media (min-width: 1400px) {

  .container,
  .container-lg,
  .container-md,
  .container-sm,
  .container-xl,
  .container-xxl {
    max-width: 1400px;
  }
}

.App1 {
  font-family: sans-serif;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 60vh;
}