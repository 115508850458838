@import url(https://fonts.googleapis.com/css2?family=Poppins:wght@300;500;700&display=swap);
/* custom colors  */

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  font-family: "Poppins", sans-serif;
}

:root {
  --bgcolor: #f1f5fd;
  --primary-color: #6f5de7;
  --text-color: #212122;
  --main-text-color: #1b1b1b;
}

html {
  font-size: 62.5%;
}

a {
  text-decoration: none;
  color: rgb(238, 236, 236);
}

h1,
h2,
h3,
h4,
h5,
h6 {
  color: #333333;
}

/* --------------------------------- navbar css style starts 
                        -------------------------------------------------------*/

.navbar {
  width: 90%;
  padding-left: 10%;
}

/*T*/

.navbar-bg {
  background-color: #f1f5fd;
  background-color: var(--bgcolor);
  padding: 2rem 0;
  z-index: 99;
  position: relative;
}

.navbar-brand {
  font-size: 4.5rem;
}

.navbar li {
  padding: 0 2rem;
}

.navbar li a {
  font-size: 1.75rem;
  /*font-size: 2.2rem;*/
  font-weight: normal;
  /*font-weight:bolder;*/
  position: relative;
}

.navbar li a:hover {
  color: #6f5de7 !important;
  color: var(--primary-color) !important;
  text-shadow: 0 0.2rem 0.3rem rgba(183, 171, 255, 0.5);
}

.btn-style {
  width: 12rem;
  height: 4rem;
  border-radius: 3rem;
  color: #fff;
  background-color: #6f5de7;
  background-color: var(--primary-color);
  text-transform: capitalize;
  font-size: 1.6rem;
}

.btn-style1 {
  width: 18rem;
  height: 4rem;
  border-radius: 3rem;
  color: #fff;
  background-color: #6f5de7;
  background-color: var(--primary-color);
  text-transform: capitalize;
  font-size: 1.6rem;
}


.btn-style:first-child {
  margin-right: 2rem;
}

.btn-style:hover {
  border: 0.1rem solid #6f5de7;
  border: 0.1rem solid var(--primary-color);
  background-color: transparent;
  color: #6f5de7;
  color: var(--primary-color);
  box-shadow: 0 0.2rem 0.5rem rgba(183, 171, 255, 0.5);
}

.btn-style1:hover {
  border: 0.1rem solid #6f5de7;
  border: 0.1rem solid var(--primary-color);
  background-color: transparent;
  color: #6f5de7;
  color: var(--primary-color);
  box-shadow: 0 0.2rem 0.5rem rgba(183, 171, 255, 0.5);
}

.btn-style-border {
  border: 0.1rem solid #6f5de7;
  border: 0.1rem solid var(--primary-color);
  background-color: transparent;
  color: #6f5de7;
  color: var(--primary-color);
  box-shadow: 0 0.2rem 0.5rem rgba(183, 171, 255, 0.5);
}

.btn-style-border:hover {
  color: #fff;
  background-color: #6f5de7;
  background-color: var(--primary-color);
  text-transform: capitalize;
}

/* --------------------------------- Navbar ends  
                        -------------------------------------------------------*/

/* --------------------------------- Hero sEction  starts  
                        -------------------------------------------------------*/
header {
  background-color: #f1f5fd;
  background-color: var(--bgcolor);
  min-height: 80vh;
  width: 100%;
}

.my-display-3 {
  font-size: 32px;
  margin-bottom: 1.5rem;
}

.maroon{color :#e31f23;}
.no-padding-top {
  padding-top: 0;
}

.main-hero-container {
  height: 90vh;
  display: flex;
  /* background-color: aqua; */
}

.main-hero-container h3 {
  text-transform: capitalize;
  font-weight: bolder;
}

.main-hero-para {
  font-size: 1.8rem;
  color: #212122;
  color: var(--text-color);
  margin: 3rem 0 3rem 0;
  /*T*/
  font-weight: lighter;
  width: 75%;
}

.main-hero-container h3 {
  font-weight: lighter;
}

.input-group {
  position: relative !important;
}

.form-control-text {
  position: absolute;
  height: 5rem;
  border: none;
  outline-style: none;
  padding-left: 1.5rem !important;
  font-size: 1.4rem;
  font-weight: lighter;
}

.input-group-button {
  position: absolute;
  right: 79%;
  top: 0.2rem;
  color: #fff;
  background-color: #6f5de7;
  background-color: var(--primary-color);
  height: 4rem;
  width: 22%;
  border-radius: 2rem !important;
  text-align: center;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 1.4rem;
}

/* right side of hero section  */

.main-herosection-images {
  position: relative;
}

.main-herosection-images img {

  /* width: 50%; */
  /*w-60,h-75*/
  /*T*/
  /* height: 50%; */
  width: 100%;
  height: 100%;
  /* margin-top: 5rem; */
  /*10rem*/
  box-shadow: 0 0.8rem 0.8rem rgba(0, 0, 0, 0.25);
  border-radius: 1.5rem;
  /*position: absolute;*/
  /*T*/
}

.main-herosection-images .main-hero-img2 {
  position: absolute;
  width: 35%;
  /*w-50,h-30*/
  /*T*/
  height: 20%;
  left: 55%;
  top: 5%;
  /*-2%*/
  box-shadow: 0.8rem 0 0.8rem rgba(0, 0, 0, 0.25);
}

.p-font {
  font-size: 2rem;
}

/* --------------------------------- Hero sEction  ends  
                        -------------------------------------------------------*/

/* --------------------------------- Work Section below hero section   starts  
                        -------------------------------------------------------*/

.work-container {
  width: 80vw;
  padding: 5rem;
  box-shadow: 0 0.4rem 1.2rem rgba(0, 0, 0, 0.1);
  margin-top: -15rem;
  /*-10rem*/
  /*T*/
  background-color: #fff;
  border-radius: 0.5rem;
}

.work-container .main-heading {
  font-size: 3rem;
  font-weight: bolder;
  margin-bottom: 4rem;
  word-spacing: 1rem;
}

.work-container-subdiv {
  padding: 0 1rem;/*T*//*0 6rem*/
}

.my-work-container-subdiv {
  /*T*/
  padding: 0;
  border: 1px solid grey;
}

.my-work-container-subdiv img {
  width: 100%
}

/*T*/

.work-container .sub-heading {
  font-size: 2rem;
  font-weight: normal;
}

.fontawesome-style {
  font-size: 2.3rem;
  color: #6f5de7;
  color: var(--primary-color);
  width: 6rem;
  height: 6rem;
  border-radius: 50%;
  background-color: #f1f5fd;
  background-color: var(--bgcolor);
  display: grid;
  place-items: center;
  text-align: center;
  margin: 2rem auto;
}
.trophy2{width:20%;text-align: center;}
.trophy2 img{width:165px;height:auto;}
.my-main-hero-para{margin: 1.5rem 0;font-size: 1.8rem;font-weight: 100;}
/* --------------------------------- Work Section below hero section   ends  
                        -------------------------------------------------------*/

/* --------------------------------- About Us Section STarts  
                        -------------------------------------------------------*/
.common-section {
  margin: 5rem 0;
}

.our-services img {
  width: 40%;
  height: 75%;
  margin-top: 10rem;
  box-shadow: rgba(0, 0, 0, 0.25) 0px 0.8rem 0.8rem;
  border-radius: 1.5rem;
}

.my-our-service-img img {
  margin-left:30%;
}
.our-services .mini-title {
  text-transform: uppercase;
  font-weight: normal;
  margin: 3rem 0;
  text-align: left;
  font-size: 1.5rem;
}

.our-services .main-heading {
  font-weight: bolder;
}

.our-services-info {
  margin-top: 4rem;
}

.our-services .our-services-number {
  width: 3rem;
  height: 3rem;
  border-radius: 50%;
  background-color: transparent;
  border: 0.1rem solid #6f5de7;
  border: 0.1rem solid var(--primary-color);
  font-size: 1.5rem;
  color: #6f5de7;
  color: var(--primary-color);
  display: grid;
  place-items: center;
}

.our-services-data {
  padding-left: 2rem;

}

.our-services-data a {
  padding-left: 2rem;
  color: #0081d1;
}

.our-services .our-services-data p {
  margin: 1rem 0;
}

/* 2nd section of about us  */

.our-services-rightside {
  margin-top: 5rem;/*10rem*//*T*/
}

.our-services-rightside-content {
  padding-left: 8rem;
}

/* --------------------------------- About Us Section ends  
                        -------------------------------------------------------*/
/* --------------------------------- Services Section starts  
                        -------------------------------------------------------*/

.service-main-container {
  margin-top: 5rem;
  padding: 5rem 0;
}

.service-main-container .main-heading {
  margin: 3rem 0;
}

.work-container-subdiv {
  box-shadow: 0 0.1rem 0.4rem rgba(0, 0, 0, 0.16);
}

.work-container-subdiv:hover {
  background-color: #f1f5fd;
  background-color: var(--bgcolor);
  cursor: pointer;
}

.work-container-subdiv:hover>.fontawesome-style {
  background-color: #fff;
}

.work-container-subdiv .main-hero-para {
  width: 100%;
}

.service-main-container .work-container-subdiv .fontawesome-style {
  text-align: left;
  margin: 2rem 0;
}

@media (min-width: 1400px) {
  .service-container .col-xxl-4 {
    flex: 0 0 auto;
    width: 30% !important;
    margin: auto;
  }
}

@media (min-width: 992px) {
  .service-container .col-lg-4 {
    flex: 0 0 auto;
    width: 30% !important;
    margin: 2.5rem auto;
  }
}

/* --------------------------------- Services Section ends  
                        -------------------------------------------------------*/

/* --------------------------------- Contact US Section starts  
                        -------------------------------------------------------*/
.contactus-section {
  margin: 15rem 0;
}

.contact-leftside img {
  min-width: 80%;
  max-height: 30rem;
  height: auto;
}

.contact-input-feild {
  margin-bottom: 4rem;
}

.contact-rightside form .form-control {
  border: 1px solid #ced4da;
  -webkit-appearance: none;
  height: 5rem;
  border-radius: 0.5rem;
  font-size: 1.6rem;
  padding-left: 1rem;
  font-weight: lighter;
}

.contact-rightside form .form-check-input {
  width: 2rem;
  height: 2rem;
}

.form-checkbox-style {
  margin: 4rem 0;
}

.contact-rightside form .main-hero-para {
  font-size: 1.5rem;
  color: #212122;
  color: var(--text-color);
  margin: 0;
  font-weight: lighter;
  width: 75%;
  padding: 0 2rem;
}

/* --------------------------------- Contact US Section ends  
                        -------------------------------------------------------*/

/* --------------------------------- Footer  Section starts  
                        -------------------------------------------------------*/

footer {
  padding: 5rem 0;
  background-color: #5c5c5c;
  /*#182334*/
  color: #fff;
}

footer h2 {
  color: #fff;
  font-size: 14px;
  /*T*/
}

.white {
  color: #fff;
}

/*T*/
.white:hover {
  color: #fff;
}

footer li {
  list-style: none;
  font-size: 1.5rem;
  font-weight: lighter;
  color: #fff;
  cursor: pointer;
  margin: 1rem 0;
}

footer li:hover {
  /*color: var(--primary-color);*/
}

footer .fontawesome-style {
  font-size: 2.3rem;
  color: #6f5de7;
  color: var(--primary-color);
  background-color: transparent;
  width: 0rem;
  height: 0rem;
  margin: 2rem 0;
  cursor: pointer;
}

footer .fontawesome-style:hover {
  transition: all 1s;
  animation: rotatefont 1s linear infinite alternate-reverse;
  color: #fff;
}

@-webkit-keyframes rotatefont {
  0% {
    -webkit-transform: scale(0.6);
            transform: scale(0.6);
  }

  100% {
    -webkit-transform: scale(1.2);
            transform: scale(1.2);
  }
}

@keyframes rotatefont {
  0% {
    -webkit-transform: scale(0.6);
            transform: scale(0.6);
  }

  100% {
    -webkit-transform: scale(1.2);
            transform: scale(1.2);
  }
}

footer .main-hero-para {
  margin: 0;
}

/* --------------------------------- Footer  Section ends  
                        -------------------------------------------------------*/

/* --------------------------------- 404 Error Page Section 
                        -------------------------------------------------------*/

#notfound {
  position: relative;
  height: 100vh;
}

#notfound .notfound {
  position: absolute;
  left: 50%;
  top: 50%;
  -webkit-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
}

.notfound {
  max-width: 920px;
  width: 100%;
  line-height: 1.4;
  text-align: center;
  padding-left: 15px;
  padding-right: 15px;
}

.notfound .notfound-404 {
  position: absolute;
  height: 100px;
  top: 0;
  left: 50%;
  -webkit-transform: translateX(-50%);
  transform: translateX(-50%);
  z-index: -1;
}

.notfound .notfound-404 h1 {
  font-family: "Maven Pro", sans-serif;
  color: #ececec;
  font-weight: 900;
  font-size: 276px;
  margin: 0px;
  position: absolute;
  left: 50%;
  top: 50%;
  -webkit-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
}

.notfound h2 {
  font-family: "Maven Pro", sans-serif;
  font-size: 46px;
  color: #000;
  font-weight: 900;
  text-transform: uppercase;
  margin: 0px;
}

.notfound p {
  font-size: 16px;
  color: #000;
  font-weight: 400;
  text-transform: uppercase;
  margin-top: 15px;
}

.notfound a {
  font-size: 14px;
  text-decoration: none;
  text-transform: uppercase;
  background: #189cf0;
  display: inline-block;
  padding: 16px 38px;
  border: 2px solid transparent;
  border-radius: 40px;
  color: #fff;
  font-weight: 400;
  transition: 0.2s all;
}

.notfound a:hover {
  background-color: #fff;
  border-color: #189cf0;
  color: #189cf0;
}

/* ---------------------------------Forth Component----------------------------------------------*/


.forthCompbg {
  background-color: #eeecfa;
  min-height: 35vh;/*60vh*//*T*/
  width: 100%;
}

.forth-container {
  width: 80vw;
  padding: 5rem;
  box-shadow: 0 0.4rem 1.2rem rgba(0, 0, 0, 0.1);
  /*margin-top: 10rem;*/
  /*T*/
  background-color: #eeecfa;
  border-radius: 0.5rem;
}

.forth-container .main-heading {
  font-size: 3rem;
  font-weight: bolder;
  margin-bottom: 4rem;
  word-spacing: 1rem;
}

.forth-container-subdiv {
  padding: 0 6rem;
}

.forth-container .sub-heading {
  font-size: 2rem;
  font-weight: normal;
}





@media only screen and (max-width: 480px) {
  .notfound .notfound-404 h1 {
    font-size: 162px;
  }

  .notfound h2 {
    font-size: 26px;
  }
}

/* --------------------------------- 404 Error Page Section  Ends
                        -------------------------------------------------------*/

/* --------------------------------- responsive media queries  
                        -------------------------------------------------------*/

@media (max-width:768px) {   /*T*/
  .trophy2{width:100%}
  .my-display-3 {font-size: 24px;margin-bottom:15px;}
  .col-6 {width: auto }
  .our-services img {margin-top: 0;}
  .my-our-service-img img{margin-top: 8rem;}
  .work-container .main-heading {font-weight: normal;}
  .forth-container .main-heading {font-size: 2rem;font-weight: normal;margin-bottom: 4rem;word-spacing: normal;}
  .our-services-rightside-content {padding-left: 6rem;}
}

@media (min-width:768px) and (max-width:920px){
  header {min-height: 60vh;}
}

@media (min-width: 1500px) {

  .container,
  .container-lg,
  .container-md,
  .container-sm,
  .container-xl,
  .container-xxl {
    /*max-width: 1500px !important;*/
    /*T*/
  }
}

@media (min-width: 1400px) {

  .container,
  .container-lg,
  .container-md,
  .container-sm,
  .container-xl,
  .container-xxl {
    max-width: 1400px;
  }
}

.App1 {
  font-family: sans-serif;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 60vh;
}
/*=============================================================
    Authour URI: Rakesh
    License: Commons Attribution 3.0

   

    100% Free To use For Personal And Commercial Use.
    IN EXCHANGE JUST GIVE US CREDITS AND TELL YOUR FRIENDS ABOUT US
   
    ========================================================  */
/* =============================================================
   GENERAL STYLES
 ============================================================ */
  body {
    overflow-x: hidden!important;
    /*font-family: 'Open Sans', sans-serif;
        line-height:30px;
        font-size:16px;*/
} 

section {
    padding-bottom:40px;/*padding-bottom:80px;*//*T*/
    padding-top: 40px;/*padding-top:75px;*/
}
 
.hr-set {
    border-top:2px solid rgb(75, 75, 75);
    max-width:100%;
}
/* h3 {
    padding:15px 5px;
    font-weight:900;
    text-transform:capitalize;
}
h4 {
    padding:15px 5px;
    font-weight:900;
    text-transform:capitalize;
} */
.h1-custom-padding
{
    Padding-top:10px!important;
}
.icon-custom-1 {
    background-color: #fff;
padding: 25px;
border-radius: 10px;
cursor:pointer;
}
.icon-custom-2 {
      background-color: #49CD7D;
padding: 25px;
border-radius:50%;
width:100px;
height:100px;
cursor:pointer;
}
.icon-custom-1:hover {
     background-color: #000;
}
.icon-custom-2:hover {
     background-color: #000;
}
.color-1 {
    color: #49CD7D;
}
.color-2 {
    color: #fff;
}
.p-top-row {
    padding-top:40px;
}

 /* =============================================================
   MESU STYLES 
 ============================================================ */

/* .navbar-inverse .navbar-brand,.navbar-inverse .navbar-brand:hover {
color: #FFF;
font-size: 35px;
letter-spacing: 5px;
font-weight: 900;
background-color:rgba(20, 152, 152, 1);
padding-bottom: 60px;
}

.navbar-inverse .navbar-collapse, .navbar-inverse .navbar-form {
border-color: rgba(16, 16, 16, 0);
}
.navbar-inverse {
border-color: rgba(16, 16, 16, 0);
}
.navbar-fixed-top {
top: 0;
border-width: 0 0 0px;
}
.set-links {
    padding: 15px 20px 10px 20px;
}
.navbar-inverse .navbar-nav > li > a {
    color:#fff;
    font-weight:900;
}
.navbar-inverse .navbar-nav > li > a:hover {
    color:rgba(20, 152, 152, 1);
}
.active-menu-item {
    color:rgba(20, 152, 152, 1)!important;
}
.navbar-brand small {
    font-size:12px;
    font-weight:normal;
    letter-spacing:1px;
} */

  /* =============================================================
   HOME SECTION STYLES -IN HOME / INDEX.HTML 
 ============================================================ */
#home-sec {
      background: url(/static/media/header.179e95ee.jpg) no-repeat center center;
padding: 0;
background-size: cover;
-moz-background-size:cover;
color:#fff;
}

#home-sec-custom {
    background: url(/static/media/header.179e95ee.jpg) no-repeat center center;
padding: 0;
background-size: cover;
-moz-background-size:cover;
color:#fff;
}

    #home-sec .overlay {
        background-color:rgb(121 100 185 / 90%);/*background-color:rgba(36, 11, 95, 0.9);*//*T*/
        min-height:350px;
    }
    #home-sec-custom .overlay {
        background-color:rgb(121 100 185 / 90%);/*background-color:rgba(36, 11, 95, 0.9);*//*T*/
        min-height:350px;
    }
    #home-sec h1 {
        padding-top:110px;/*150px*//*T*/
        font-weight:900;
		color: rgb(246, 246, 246);
    }
    #home-sec-custom h1 {
        padding-top:70px;/*150px*//*T*/
        font-weight:900;
		color: rgb(246, 246, 246);
    }
#home-sec p {
    padding:10px;
	font-size:17px;
}

#home-sec p h3{
    color:#ffffff;
    font-size: 17px;
}
/* /* =============================================================
   SEARCH DOMAIN SECTION STYLES  -IN HOME / INDEX.HTML 
 ============================================================ */
/* #search-domain {
    padding-bottom:50px;
    padding-top:50px;
}
.input-cls {
    height: 70px;
    border: 3px solid #2EB1D0;
    font-size:30px;
    color:#2EB1D0;
    padding-left:40px;
    border-radius:0px;
    -webkit-border-radius:0px;
    -moz-border-radius:0px;
}
.btn-set {
    font-size:30px;
    border: 5px double #fff;
    border-radius:0px;
    -webkit-border-radius:0px;
    -moz-border-radius:0px;
} */ */
/* =============================================================
   SERVICES SECTION STYLES  -IN HOME / INDEX.HTML 
 ============================================================ */

/* #services-sec {
    background-color:#202020;
    color:#fff;
} */

   /* =============================================================
   CLIENT TESTIMONIAL SECTION STYLES  -IN HOME / INDEX.HTML 
 ============================================================ */
/* #testimonial-sec {
  background-color:#43698E;
}

.carousel-indicators .active {
width: 25px;
height: 25px;
background-color: #fff;
}
.carousel-indicators li {
width: 25px;
height: 25px;
border: 1px solid #fff;
}
   #carousel-example .slide-custom {
    min-height:300px;
    padding:20px;
    line-height:30px;
}

#carousel-example h1, h2 {
    color:rgb(199, 199, 199);
}
#carousel-example h4 {
    line-height:30px;
    font-size:16px;
    color: #fff;
}
#carousel-example h4 i{
   padding:5px;
}
#carousel-example .pad-top {
     padding-top:80px;
}
#carousel-example {
    margin-top:50px;

}

#carousel-example .user-img {
border: 5px solid #49CD7D;
overflow: hidden;
border-radius: 50%;
display: inline-block;
margin-left:20px;
}

#carousel-example .img-u {
   max-height: 90px;
max-width: 90px;

}

#carousel-example .c-black {
    color:#49CD7D;
    font-style: italic;
font-size: 20px;
padding: 5px;
} */
/* =============================================================
   FEATURES SECTION STYLES  -IN HOME / INDEX.HTML 
 ============================================================ */
#features-sec {
    background-color:#EFEFEF;
}
/* =============================================================
   FOOTER SECTION STYLES
 ============================================================ */
/* #footer-sec {
    background-color:#121212;
    padding-bottom:30px;
    padding-top:30px;
    color: #DFD1D1;
}
.social-links a,.social-links a:hover{
    color:#fff;
    text-decoration:none;
}

.social-links a i {
    background-color:#000;
    padding:15px 20px;
    margin:5px;
}
.copy-txt {
    background-color:#202020;
    padding:15px;
    color:#7C7C7C;
}

.set-foot {
    text-align:right;
    padding-right:50px;
} */

/* =============================================================
   PAGE HEADLINE STYLES
 ============================================================ */
.headline-sec {
      background: url(/static/media/header.179e95ee.jpg) no-repeat center center;
padding: 0;
background-size: cover;
-moz-background-size:cover;
color:#fff;
}
.headline-sec .overlay {
        background-color:rgba(20, 152, 152, 0.9);
        min-height:170px;
    }
    .headline-sec h3 {
        padding-top:100px;
        font-weight:900;
        padding-left:100px;
    }

     /* =============================================================
   PRICING STYLES --pricing.html page
 ============================================================ */

#pricing-sec h3 {
   color:#2784DF;
}
.pricing-div ul{
    padding-top:30px;
    list-style:none;
    
    
}

    .pricing-div ul li {
        padding-left:0px;
        padding-bottom:20px;
        
    }
        .pricing-div ul li i {
            padding-right:10px;
        }
.pricing-div  .price {
    font-size:30px;
    font-weight:800;
    display:block;
}
.set-wth {
    max-width:400px;
}
